import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Parse from "parse";
import { useNavigate } from "react-router";
import Alert from "../../primitives/Alert";
import Loader from "../../primitives/Loader";
import { saveLanguageInLocal } from "../../constant/Utils";

const ActivatePlan = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({ email: "", activationkey: "" });
  const [isAlert, setIsAlert] = useState({ type: "success", msg: "" });
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const fetchUser = () => {
    setIsLoader(true);
    try {
      const user = Parse.User.current();
      if (user) {
        setFormdata((prev) => ({ ...prev, email: user?.getEmail() }));
        setIsLoader(false);
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log("Err", err);
    }
  };
  const handleChange = (e) => {
    setFormdata((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      const params = {
        Email: formdata.email,
        ActivationKey: formdata.activationkey
      };
      const res = await Parse.Cloud.run("activateplan", params);
      if (res) {
        navigate("/dashboard/35KBoSgoAK");
      }
    } catch (err) {
      setIsAlert({ type: "danger", msg: err.message });
      console.log("Err while activate plan", err);
    } finally {
      setIsLoader(false);
      setTimeout(() => setIsAlert({ type: "success", msg: "" }), 2000);
    }
  };

  const logOutUser = async () => {
    try {
      await Parse.User.logOut();
    } catch (err) {
      console.log("Err while logging out", err);
    }
    let appdata = localStorage.getItem("userSettings");
    let applogo = localStorage.getItem("appLogo");
    let defaultmenuid = localStorage.getItem("defaultmenuid");
    let PageLanding = localStorage.getItem("PageLanding");
    let baseUrl = localStorage.getItem("baseUrl");
    let appid = localStorage.getItem("parseAppId");

    localStorage.clear();
    saveLanguageInLocal(i18n);

    localStorage.setItem("appLogo", applogo);
    localStorage.setItem("defaultmenuid", defaultmenuid);
    localStorage.setItem("PageLanding", PageLanding);
    localStorage.setItem("userSettings", appdata);
    localStorage.setItem("baseUrl", baseUrl);
    localStorage.setItem("parseAppId", appid);
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Alert type={isAlert.type}>{isAlert.msg}</Alert>
      {isLoader && (
        <div
          aria-live="assertive"
          className="fixed w-full h-full flex justify-center items-center bg-black bg-opacity-30 z-50"
        >
          <Loader />
        </div>
      )}
      <div className="bg-base-100 op-card w-full md:w-[500px]">
        <div className="op-card-body">
          <h2 className="op-card-title font">Activate Plan</h2>
          <form onSubmit={handleSubmit}>
            <div className="my-1">
              <label className="block text-xs" htmlFor="email">
                {t("email")}
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formdata.email}
                onChange={handleChange}
                onInvalid={(e) =>
                  e.target.setCustomValidity(t("input-required"))
                }
                onInput={(e) => e.target.setCustomValidity("")}
                className="op-input op-input-bordered op-input-sm focus:outline-none hover:border-base-content w-full text-xs"
                disabled={formdata.email}
                required
              />
            </div>
            <div className="my-1">
              <label className="block text-xs" htmlFor="activationkey">
                {t("activation-key")}
              </label>
              <input
                type="text"
                id="activationkey"
                name="activationkey"
                value={formdata.activationkey}
                onChange={handleChange}
                onInvalid={(e) =>
                  e.target.setCustomValidity(t("input-required"))
                }
                onInput={(e) => e.target.setCustomValidity("")}
                className="op-input op-input-bordered op-input-sm focus:outline-none hover:border-base-content w-full text-xs"
                required
              />
            </div>
            <div className="flex flex-row gap-2 mt-3">
              <button type="submit" className="op-btn op-btn-primary">
                Activate
              </button>
              <button
                type="button"
                className="op-btn op-btn-secondary"
                onClick={logOutUser}
              >
                Log out
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ActivatePlan;
