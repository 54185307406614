import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { subscriptionpath } from "../../constant/const";
import { openInNewTab } from "../../constant/Utils";

const QuotaCard = ({ isPaidInfo, handlClose, emailResetDate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLearnNow = () => {
    openInNewTab("https://youtu.be/jZDNhhaFEE0");
  };
  return (
    <>
      {isPaidInfo && (
        <div
          className="op-btn op-btn-sm op-btn-circle op-btn-ghost text-primary-content absolute right-2 top-2 z-40"
          onClick={() => handlClose && handlClose()}
        >
          ✕
        </div>
      )}
      <div className="op-card op-bg-primary text-primary-content w-full shadow-lg">
        <div className="op-card-body">
          {isPaidInfo ? (
            <>
              <h2 className="op-card-title">{t("quota-mail-info-head")}</h2>
              <p>{t("quota-mail-info")}</p>
            </>
          ) : (
            <p>{t("quota-mail")}</p>
          )}
          {emailResetDate && (
            <p>
              {t("quota-mail-reset")} {emailResetDate}
            </p>
          )}
          <div>
            <Trans i18nKey={"quota-mail-tip"}>
              Tip: You can still sign <strong>unlimited documents</strong> by
              manually sharing the signing request link.
            </Trans>
            <span
              onClick={() => handleLearnNow()}
              className="ml-1 cursor-pointer hover:underline"
            >
              <strong>Learn how.</strong>
            </span>
          </div>
          <div className="op-card-actions justify-end">
            <button
              onClick={() => navigate(subscriptionpath)}
              className="op-btn op-btn-accent"
            >
              {t("upgrade-now")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotaCard;
