export const contactCls = "contracts_Contactbook";
export const templateCls = "contracts_Template";
export const documentCls = "contracts_Document";
export const themeColor = "#47a3ad";
export const iconColor = "#686968";
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// start_ee_code
export const isEnableSubscription =
  process.env.REACT_APP_ENABLE_SUBSCRIPTION &&
  process.env.REACT_APP_ENABLE_SUBSCRIPTION?.toLowerCase() === "true"
    ? true
    : false;
export const isStaging =
  window.location.origin === "https://staging-app.opensignlabs.com";
export const isSandbox =
  window.location.origin === "https://sandbox.opensignlabs.com";
export const isPublicStaging =
  window.location.origin === "https://staging.opensign.me";
export const isPublicEurope =
  window.location.origin === "https://eu.opensign.me";
export const domains = {
  "https://app.opensignlabs.com": "global",
  "https://eu-app.opensignlabs.com": "europe"
};
export const region = domains[window.location.origin] || "global";
export const consoleUrl = {
  global: "https://console.opensignlabs.com",
  europe: "https://eu-console.opensignlabs.com"
};
export const publicUrl = {
  global: "https://opensign.me",
  europe: "https://eu.opensign.me"
};
export const regionList = ["global", "europe"];
export const deployment = process.env.REACT_APP_DEPLOYMENT;
export const isCloudDeployment = deployment === "cloud";
export const isPaidSelfhost = deployment === "paid_selfhost";
export const subscriptionpath = isCloudDeployment
  ? "/subscription"
  : "/activate";
// end_ee_code
