import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "../../styles/signature.css";
import { PDFDocument } from "pdf-lib";
import { isEnableSubscription, subscriptionpath } from "../../constant/const";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import RenderAllPdfPage from "../../components/pdf/RenderAllPdfPage";
import WidgetComponent from "../../components/pdf/WidgetComponent";
import PdfDeclineModal from "../../primitives/PdfDeclineModal";
import Tour from "reactour";
import { useLocation, useParams } from "react-router";
import Confetti from "react-confetti";
import moment from "moment";
import Header from "../../components/pdf/PdfHeader";
import {
  pdfNewWidthFun,
  contractDocument,
  addZIndex,
  randomId,
  defaultWidthHeight,
  multiSignEmbed,
  textInputWidget,
  textWidget,
  radioButtonWidget,
  color,
  getTenantDetails,
  fetchSubscription,
  convertPdfArrayBuffer,
  getContainerScale,
  onClickZoomIn,
  onClickZoomOut,
  rotatePdfPage,
  handleRemoveWidgets,
  handleRotateWarning,
  signatureTypes,
  handleSignatureType,
  getBase64FromUrl,
  embedDocId,
  signPdfFun,
  onSaveSign,
  onSaveImage,
  fetchUrl,
  openInNewTab,
  handleToPrint,
  handleDownloadCertificate,
  getDate,
  addWidgetSelfsignOptions,
  compressedFileSize,
  formatTimeInTimezone
} from "../../constant/Utils";
import RenderPdf from "../../components/pdf/RenderPdf";
import { useNavigate } from "react-router";
import PlaceholderCopy from "../../components/pdf/PlaceholderCopy";
import Title from "../../components/Title";
import DropdownWidgetOption from "../../components/pdf/DropdownWidgetOption";
import WidgetNameModal from "../../components/pdf/WidgetNameModal";
import { useSelector } from "react-redux";
import PdfZoom from "../../components/pdf/PdfZoom";
import { useTranslation } from "react-i18next";
import RotateAlert from "../../components/RotateAlert";
import Loader from "../../primitives/Loader";
import ModalUi from "../../primitives/ModalUi";
import HandleError from "../../primitives/HandleError";
import LoaderWithMsg from "../../primitives/LoaderWithMsg";
import Signedby from "../../components/pdf/Signedby";
import SignPad from "../../components/pdf/SignPad";
import TextFontSetting from "../../components/pdf/TextFontSetting";
import { serverUrl_fn } from "../../constant/appinfo";
import DownloadPdfZip from "../../primitives/DownloadPdfZip";

function SelfSign() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [pdfDetails, setPdfDetails] = useState([]);
  const [allPages, setAllPages] = useState(null);
  const numPages = 1;
  const [pageNumber, setPageNumber] = useState(1);
  const [signBtnPosition, setSignBtnPosition] = useState([]);
  const [xySignature, setXYSignature] = useState({});
  const [dragKey, setDragKey] = useState();
  const [signersdata, setSignersData] = useState([]);
  const [signerPos, setSignerPos] = useState([]);
  const [isSelectListId, setIsSelectId] = useState();
  const [fontSize, setFontSize] = useState();
  const [fontColor, setFontColor] = useState();
  const [isLoading, setIsLoading] = useState({
    isLoad: true,
    message: t("loading-mssg")
  });
  const [handleError, setHandleError] = useState();
  const [pdfNewWidth, setPdfNewWidth] = useState();
  const [validateAlert, setValidateAlert] = useState(false);
  const [pdfOriginalWH, setPdfOriginalWH] = useState([]);
  const [containerWH, setContainerWH] = useState();
  const { docId, contactBookId } = useParams();
  const signerObjectId = contactBookId;
  const dragRef = useRef(null);
  const divRef = useRef(null);
  const [isCompleted, setIsCompleted] = useState({
    isCertificate: false,
    isModal: false
  });
  const [isAlert, setIsAlert] = useState({ isShow: false, alertMessage: "" });
  const [isResize, setIsResize] = useState(false);
  const [zIndex, setZIndex] = useState(1);
  const [signKey, setSignKey] = useState();
  const [blockColor, setBlockColor] = useState("");
  const [isTextSetting, setIsTextSetting] = useState(false);
  const [pdfLoad, setPdfLoad] = useState(false);
  const [isPageCopy, setIsPageCopy] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [widgetType, setWidgetType] = useState("");
  const [isUiLoading, setIsUiLoading] = useState(false);
  const [currWidgetsDetails, setCurrWidgetsDetails] = useState({});
  const [selectWidgetId, setSelectWidgetId] = useState("");
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isNameModal, setIsNameModal] = useState(false);
  const [widgetName, setWidgetName] = useState(false);
  const [widgetsTour, setWidgetsTour] = useState(false);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [isInitial, setIsInitial] = useState(false);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState("");
  const isHeader = useSelector((state) => state.showHeader);
  const [isCelebration, setIsCelebration] = useState(false);
  const [imgWH, setImgWH] = useState({});
  const [signature, setSignature] = useState();
  const [isExpired, setIsExpired] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const imageRef = useRef(null);
  const [isSignPad, setIsSignPad] = useState(false);
  const [isStamp, setIsStamp] = useState(false);
  const [isImageSelect, setIsImageSelect] = useState(false);
  const [image, setImage] = useState(null);
  const [signedSigners, setSignedSigners] = useState([]);
  const [showRotateAlert, setShowRotateAlert] = useState({
    status: false,
    degree: 0
  });
  const [zoomPercent, setZoomPercent] = useState(0);
  const [scale, setScale] = useState(1);
  const [pdfBase64Url, setPdfBase64Url] = useState("");
  const [unSignedWidgetId, setUnSignedWidgetId] = useState("");
  const [signatureType, setSignatureType] = useState(signatureTypes);
  const [isSigned, setIsSigned] = useState(false);
  const [expiredDate, setExpiredDate] = useState("");
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [redirectTimeLeft, setRedirectTimeLeft] = useState(5);
  const [isredirectCanceled, setIsredirectCanceled] = useState(true);
  const [isDownloading, setIsDownloading] = useState("");
  const isMobile = window.innerWidth < 767;
  const [, drop] = useDrop({
    accept: "BOX",
    drop: (item, monitor) => addPositionOfSignature(item, monitor),
    collect: (monitor) => ({ isOver: !!monitor.isOver() })
  });
  const [{ isDragSign }, dragSignature] = useDrag({
    type: "BOX",
    item: { type: "BOX", id: 1, text: "signature" },
    collect: (monitor) => ({ isDragSign: !!monitor.isDragging() })
  });
  const [{ isDragStamp }, dragStamp] = useDrag({
    type: "BOX",
    item: { type: "BOX", id: 2, text: "stamp" },
    collect: (monitor) => ({ isDragStamp: !!monitor.isDragging() })
  });
  const documentId = docId;
  useEffect(() => {
    if (documentId) {
      getDocumentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTenantDetails = async (contactId) => {
    const user = JSON.parse(
      localStorage.getItem(
        `Parse/${localStorage.getItem("parseAppId")}/currentUser`
      )
    );
    try {
      const tenantDetails = await getTenantDetails(
        user?.objectId, // userId
        "", // jwttoken
        contactId // contactId
      );
      if (tenantDetails && tenantDetails === "user does not exist!") {
        alert(t("user-not-exist"));
      } else if (tenantDetails) {
        const signatureType = tenantDetails?.SignatureType || [];
        const filterSignTypes = signatureType?.filter(
          (x) => x.enabled === true
        );

        return filterSignTypes;
      }
    } catch (e) {
      alert(t("user-not-exist"));
    }
  };

  useEffect(() => {
    const updateSize = () => {
      if (divRef.current) {
        const pdfWidth = pdfNewWidthFun(divRef);
        setPdfNewWidth(pdfWidth);
        setContainerWH({
          width: divRef.current.offsetWidth,
          height: divRef.current.offsetHeight
        });
        setScale(1);
        setZoomPercent(0);
      }
    };
    // Use setTimeout to wait for the transition to complete
    const timer = setTimeout(updateSize, 100); // match the transition duration
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current, isHeader]);
  const redirectUrl = pdfDetails?.[0]?.RedirectUrl || "";
  useEffect(() => {
    if (isredirectCanceled) return; // Stop the redirect timer if canceled
    if (redirectUrl) {
      if (redirectTimeLeft === 0) {
        openInNewTab(redirectUrl, "_self"); // Replace with your target URL
      }
      const timer = setTimeout(() => {
        setRedirectTimeLeft((prev) => prev - 1); // Decrement the timer
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [redirectTimeLeft, isredirectCanceled, redirectUrl]);
  const handleNavigation = () => {
    navigate(subscriptionpath);
  };

  //function for get document details
  const getDocumentDetails = async () => {
    const tenantSignTypes = await fetchTenantDetails(contactBookId);
    //getting document details
    const documentData = await contractDocument(documentId);
    if (documentData && documentData.length > 0) {
      const url =
        documentData[0] && (documentData[0]?.SignedUrl || documentData[0]?.URL);
      //convert document url in array buffer format to use embed widgets in pdf using pdf-lib
      const arrayBuffer = await convertPdfArrayBuffer(url);
      const base64Pdf = await getBase64FromUrl(url);
      if (arrayBuffer === "Error") {
        setHandleError(t("something-went-wrong-mssg"));
      } else {
        setPdfArrayBuffer(arrayBuffer);
        setPdfBase64Url(base64Pdf);
      }
      const isCompleted =
        documentData[0].IsCompleted && documentData[0].IsCompleted;
      const expireDate = documentData[0].ExpiryDate.iso;
      const expireUpdateDate = new Date(expireDate).getTime();
      const currDate = new Date().getTime();
      if (isEnableSubscription) {
        checkIsSubscribed(documentData[0]?.ExtUserPtr?.Email);
      }
      if (isCompleted) {
        setIsSigned(true);
        setSignedSigners(documentData[0]?.Signers);
        const data = { isCertificate: true, isModal: true };
        setIsCompleted(data);
        setIsCelebration(true);
        setTimeout(() => setIsCelebration(false), 5000);
      } else if (currDate > expireUpdateDate) {
        const expireDateFormat = moment(new Date(expireDate)).format(
          "MMM DD, YYYY"
        );
        setIsExpired(true);
        setExpiredDate(expireDateFormat);
      }
      const userSignatureType =
        documentData[0]?.ExtUserPtr?.SignatureType || signatureTypes;
      const docSignTypes =
        documentData?.[0]?.SignatureType || userSignatureType;
      const updatedSignatureType = await handleSignatureType(
        tenantSignTypes,
        docSignTypes
      );
      setSignatureType(updatedSignatureType);
      const updatedPdfDetails = [...documentData];
      updatedPdfDetails[0].SignatureType = updatedSignatureType;
      setPdfDetails(updatedPdfDetails);
      //condition when placeholder have empty array with role details and signers array have signers data
      //and both array length are same
      //this case happen using placeholder form in auto save funtionality to save draft type document without adding any placehlder
      if (
        documentData[0]?.Placeholders?.length ===
        documentData[0]?.Signers?.length
      ) {
        const signersArr = documentData[0].Signers;
        const placeholder = documentData[0].Placeholders;
        const updatedSigners = signersArr.map((x, index) => ({
          ...x,
          Id: placeholder[index]?.Id,
          Role: placeholder[index]?.Role,
          blockColor: placeholder[index]?.blockColor
        }));
        setSignerPos(placeholder);
        setSignersData(updatedSigners);
        setIsSelectId(0);
        setUniqueId(placeholder[0].Id);
        setBlockColor(placeholder[0].blockColor);
      }
      //else condition when signers array have some signer's data
      //this case happen using placeholder form and load first time
      else if (documentData[0].Signers && documentData[0].Signers.length > 0) {
        setIsSelectId(0);
        //if condition when placeholder array present then update signers local array according to placeholder length
        if (
          documentData[0].Placeholders &&
          documentData[0].Placeholders.length > 0
        ) {
          setSignerPos(documentData[0].Placeholders);
          let signers = [...documentData[0].Signers];
          const placeholder = documentData[0]?.Placeholders;
          let updatedSigners = placeholder.map((x) => {
            let matchingSigner = signers.find(
              (y) => x.signerObjId && x.signerObjId === y.objectId
            );
            if (matchingSigner) {
              return {
                ...matchingSigner,
                Role: x.Role ? x.Role : matchingSigner.Role,
                Id: x.Id,
                blockColor: x.blockColor
              };
            } else {
              return { Role: x.Role, Id: x.Id, blockColor: x.blockColor };
            }
          });
          setSignersData(updatedSigners);
          setUniqueId(updatedSigners[0].Id);
          setBlockColor(updatedSigners[0].blockColor);
        } else {
          //else condition when signers length present but placeholder empty then
          //update signers array with add role,id and add empty object in placeholder with signers details
          //in placeholder array
          const updatedSigners = documentData[0].Signers.map((x, index) => ({
            ...x,
            Id: randomId(),
            // Role: "User " + (index + 1),
            blockColor: color[index % color.length]
          }));
          setSignersData(updatedSigners);
          const updatedPlaceholder = documentData[0].Signers.map((x, index) => {
            return {
              // Role: updatedSigners[index].Role,
              Id: updatedSigners[index].Id,
              blockColor: color[index % color.length],
              signerPtr: {
                __type: "Pointer",
                className: x?.className || "contracts_Contactbook",
                objectId: x?.objectId
              },
              signerObjId: x?.objectId
            };
          });

          setSignerPos(updatedPlaceholder);
          setSignersData(updatedSigners);
          setUniqueId(updatedSigners[0].Id);
          setBlockColor(updatedSigners[0].blockColor);
        }
      } else {
        //when user create document using template where signers data not present and only placeholders present
        //else condition when signers array is empty then check placeholders array length
        //if placeholders have some data then update signers data according to placeholders length
        // setRoleName("User 1");
        if (
          documentData[0].Placeholders &&
          documentData[0].Placeholders.length > 0
        ) {
          const placeholder = documentData[0]?.Placeholders;
          let updatedSigners = placeholder.map((x) => {
            return { Role: x.Role, Id: x.Id, blockColor: x.blockColor };
          });
          setSignerPos(documentData[0].Placeholders);
          setSignersData(updatedSigners);
          setIsSelectId(0);
          setUniqueId(updatedSigners[0].Id);
          setBlockColor(updatedSigners[0].blockColor);
        }
      }
      setIsLoading({ isLoad: false });
      setIsUiLoading(false);
      if (!isCompleted) {
        const Timezone = documentData[0]?.ExtUserPtr?.Timezone || "";
        const viewedAt = formatTimeInTimezone(new Date(), Timezone);
        const createdAt = formatTimeInTimezone(
          new Date(documentData?.[0].createdAt),
          Timezone
        );
        const params = {
          event: "viewed",
          contactId: contactBookId,
          body: {
            type: "self-sign",
            objectId: documentData?.[0].objectId,
            file: documentData?.[0]?.SignedUrl || documentData?.[0]?.URL,
            name: documentData?.[0].Name,
            note: documentData?.[0].Note || "",
            signers: documentData?.[0].Signers?.map((x) => ({
              name: x?.Name,
              email: x?.Email,
              phone: x?.Phone
            })),
            viewedBy: documentData?.[0].Signers?.find(
              (x) => x.objectId === contactBookId
            )?.Email,
            viewedAt: viewedAt,
            createdAt: createdAt
          }
        };
        try {
          await axios.post(
            `${localStorage.getItem("baseUrl")}functions/callwebhook`,
            params,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
                sessiontoken: localStorage.getItem("accesstoken")
              }
            }
          );
        } catch (err) {
          console.log("Err ", err);
        }
      }
      return updatedPdfDetails;
    } else if (
      documentData === "Error: Something went wrong!" ||
      (documentData.result && documentData.result.error)
    ) {
      setHandleError(t("something-went-wrong-mssg"));
      setIsLoading({ isLoad: false });
    } else {
      setHandleError(t("no-data-avaliable"));
      setIsLoading({ isLoad: false });
    }
  };
  const getWidgetValue = (type) => {
    switch (type) {
      case "name":
        return pdfDetails[0]?.Signers[0]?.Name;
      case "company":
        return pdfDetails[0]?.Signers[0]?.Company;
      case "job title":
        return pdfDetails[0]?.Signers[0]?.JobTitle;
      case "email":
        return pdfDetails[0]?.Signers[0]?.Email;
      case "checkbox":
        return true;
      case "date":
        return getDate();
      default:
        return "";
    }
  };
  //function for setting position after drop signature button over pdf
  const addPositionOfSignature = (item, monitor) => {
    getSignerPos(item, monitor);
  };
  const getSignerPos = (item, monitor) => {
    if (uniqueId) {
      const posZIndex = zIndex + 1;
      setZIndex(posZIndex);
      const signer = signersdata.find((x) => x.Id === uniqueId);
      const key = randomId();
      const containerScale = getContainerScale(
        pdfOriginalWH,
        pageNumber,
        containerWH
      );
      let dropData = [];
      let placeHolder;
      const dragTypeValue = item?.text ? item.text : monitor.type;
      const widgetWidth =
        defaultWidthHeight(dragTypeValue).width * containerScale;
      const widgetHeight =
        defaultWidthHeight(dragTypeValue).height * containerScale;
      //adding and updating drop position in array when user drop signature button in div
      if (item === "onclick") {
        const divHeight = divRef.current.getBoundingClientRect().height;
        // `getBoundingClientRect()` is used to get accurate measurement height of the div
        const dropObj = {
          //onclick put placeholder center on pdf
          xPosition: widgetWidth / 4 + containerWH.width / 2,
          yPosition: widgetHeight + divHeight / 2,
          isStamp:
            (dragTypeValue === "stamp" || dragTypeValue === "image") && true,
          key: key,
          scale: containerScale,
          zIndex: posZIndex,
          type: dragTypeValue,
          options: addWidgetSelfsignOptions(dragTypeValue, getWidgetValue),
          Width: widgetWidth / (containerScale * scale),
          Height: widgetHeight / (containerScale * scale)
        };
        dropData.push(dropObj);
        placeHolder = { pageNumber: pageNumber, pos: dropData };
      } else {
        const offset = monitor.getClientOffset();
        //This method returns the offset of the current pointer (mouse) position relative to the client viewport.
        const containerRect = document
          .getElementById("container")
          .getBoundingClientRect();
        //`containerRect.left`,  The distance from the left of the viewport to the left side of the element.
        //`containerRect.top` The distance from the top of the viewport to the top of the element.
        const x = offset.x - containerRect.left;
        const y = offset.y - containerRect.top;
        const getXPosition = signBtnPosition[0]
          ? x - signBtnPosition[0].xPos
          : x;
        const getYPosition = signBtnPosition[0]
          ? y - signBtnPosition[0].yPos
          : y;
        const dropObj = {
          xPosition: getXPosition / (containerScale * scale),
          yPosition: getYPosition / (containerScale * scale),
          isStamp:
            (dragTypeValue === "stamp" || dragTypeValue === "image") && true,
          key: key,
          scale: containerScale,
          zIndex: posZIndex,
          type: dragTypeValue,
          options: addWidgetSelfsignOptions(dragTypeValue, getWidgetValue),
          Width: widgetWidth / (containerScale * scale),
          Height: widgetHeight / (containerScale * scale)
        };
        dropData.push(dropObj);
        placeHolder = { pageNumber: pageNumber, pos: dropData };
      }
      setSelectWidgetId(key);
      if (signer) {
        let filterSignerPos, currentPagePosition;
        filterSignerPos = signerPos.find((data) => data.Id === uniqueId);
        const getPlaceHolder = filterSignerPos?.placeHolder;
        if (getPlaceHolder) {
          //checking exist placeholder on same page
          currentPagePosition = getPlaceHolder.find(
            (data) => data.pageNumber === pageNumber
          );
        }
        //checking current page has already some placeholders then update that placeholder and add upcoming placehoder position
        if (getPlaceHolder && currentPagePosition) {
          const updatePlace = getPlaceHolder.filter(
            (data) => data.pageNumber !== pageNumber
          );
          const getPos = currentPagePosition?.pos;
          const newSignPos = getPos.concat(dropData);
          let xyPos = { pageNumber: pageNumber, pos: newSignPos };
          updatePlace.push(xyPos);
          let updatesignerPos;
          updatesignerPos = signerPos.map((x) =>
            x.Id === uniqueId ? { ...x, placeHolder: updatePlace } : x
          );
          setSignerPos(updatesignerPos);
        } else {
          //else condition to add placeholder widgets on multiple page first time
          const updatesignerPos = signerPos.map((x) =>
            x.Id === uniqueId && x?.placeHolder
              ? { ...x, placeHolder: [...x.placeHolder, placeHolder] }
              : x.Id === uniqueId
                ? { ...x, placeHolder: [placeHolder] }
                : x
          );
          setSignerPos(updatesignerPos);
        }
        if (dragTypeValue === "checkbox") {
          setIsCheckbox(true);
        } else if (
          [textWidget, "name", "company", "job title", "email"].includes(
            dragTypeValue
          )
        ) {
          setFontSize(12);
          setFontColor("black");
        }
        setWidgetType(dragTypeValue);
        setSignKey(key);
        setCurrWidgetsDetails({});
        setWidgetName(dragTypeValue);
      }
    }
  };

  //function for get pdf page details
  const pageDetails = async (pdf) => {
    let pdfWHObj = [];
    const totalPages = pdf?.numPages;
    for (let index = 0; index < totalPages; index++) {
      const getPage = await pdf.getPage(index + 1);
      const scale = 1;
      const { width, height } = getPage.getViewport({ scale });
      pdfWHObj.push({ pageNumber: index + 1, width, height });
    }
    setPdfOriginalWH(pdfWHObj);
    setPdfLoad(true);
  };

  //function for save x and y position and show signature  tab on that position
  const handleTabDrag = (key) => {
    setDragKey(key);
    setIsDragging(true);
  };

  //function for set and update x and y postion after drag and drop signature tab
  const handleStop = (event, dragElement, signerId, key) => {
    setFontColor();
    setFontSize();
    if (!isResize && isDragging) {
      const dataNewPlace = addZIndex(signerPos, key, setZIndex);
      let updateSignPos = [...signerPos];
      updateSignPos.splice(0, updateSignPos.length, ...dataNewPlace);
      const signId = signerId ? signerId : uniqueId; //? signerId : signerObjId;
      const keyValue = key ? key : dragKey;
      const containerScale = getContainerScale(
        pdfOriginalWH,
        pageNumber,
        containerWH
      );
      if (keyValue >= 0) {
        const filterSignerPos = updateSignPos.filter(
          (data) => data.Id === signId
        );
        if (filterSignerPos.length > 0) {
          const getPlaceHolder = filterSignerPos[0].placeHolder;
          const getPageNumer = getPlaceHolder.filter(
            (data) => data.pageNumber === pageNumber
          );
          if (getPageNumer.length > 0) {
            const getXYdata = getPageNumer[0].pos;
            const getPosData = getXYdata;
            const addSignPos = getPosData.map((url) => {
              if (url.key === keyValue) {
                return {
                  ...url,
                  xPosition: dragElement.x / (containerScale * scale),
                  yPosition: dragElement.y / (containerScale * scale)
                };
              }
              return url;
            });

            const newUpdateSignPos = getPlaceHolder.map((obj) => {
              if (obj.pageNumber === pageNumber) {
                return { ...obj, pos: addSignPos };
              }
              return obj;
            });
            const newUpdateSigner = updateSignPos.map((obj) => {
              if (signId) {
                if (obj.Id === signId) {
                  return { ...obj, placeHolder: newUpdateSignPos };
                }
              }
              return obj;
            });
            setSignerPos(newUpdateSigner);
          }
        }
      }
    }
    setTimeout(() => setIsDragging(false), 200);
  };
  //function for delete signature block
  const handleDeleteSign = (key, Id) => {
    const updateData = [];
    const filterSignerPos = signerPos.filter((data) => data.Id === Id);
    if (filterSignerPos.length > 0) {
      const getPlaceHolder = filterSignerPos[0].placeHolder;
      const getPageNumer = getPlaceHolder.filter(
        (data) => data.pageNumber === pageNumber
      );
      if (getPageNumer.length > 0) {
        const getXYdata = getPageNumer[0].pos.filter(
          (data) => data.key !== key
        );
        //condition to check on same has multiple widgets so do not delete all widgets
        if (getXYdata.length > 0) {
          updateData.push(getXYdata);
          const newUpdatePos = getPlaceHolder.map((obj) => {
            if (obj.pageNumber === pageNumber) {
              return { ...obj, pos: updateData[0] };
            }
            return obj;
          });

          const newUpdateSigner = signerPos.map((obj) => {
            if (obj.Id === Id) {
              return { ...obj, placeHolder: newUpdatePos };
            }
            return obj;
          });
          setSignerPos(newUpdateSigner);
        } else {
          const getRemainPage = filterSignerPos[0].placeHolder.filter(
            (data) => data.pageNumber !== pageNumber
          );
          //condition to check placeholder length is greater than 1 do not need to remove whole placeholder
          //array only resove particular widgets
          if (getRemainPage && getRemainPage.length > 0) {
            const newUpdatePos = filterSignerPos.map((obj) => {
              if (obj.Id === Id) {
                return { ...obj, placeHolder: getRemainPage };
              }
              return obj;
            });
            let signerupdate = [];
            signerupdate = signerPos.filter((data) => data.Id !== Id);
            signerupdate.push(newUpdatePos[0]);
            setSignerPos(signerupdate);
          } else {
            const updatedData = signerPos
              .filter((item) => !(item.Id === Id && item.Role === "prefill")) // Remove prefill object
              .map((item) => {
                if (item.Id === Id && item.Role !== "prefill") {
                  // Create a copy of the item object and delete the placeHolder field
                  const updatedItem = { ...item };
                  delete updatedItem.placeHolder;
                  return updatedItem;
                }
                return item;
              });
            setSignerPos(updatedData);
          }
        }
      }
    }
  };

  //function for change page
  function changePage(offset) {
    setSignBtnPosition([]);
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  //function for capture position on hover or touch widgets
  const handleDivClick = (e) => {
    const isTouchEvent = e.type.startsWith("touch");
    const divRect = e.currentTarget.getBoundingClientRect();
    let mouseX, mouseY;
    if (isTouchEvent) {
      const touch = e.touches[0]; // Get the first touch point
      mouseX = touch.clientX - divRect.left;
      mouseY = touch.clientY - divRect.top;
      setSignBtnPosition([{ xPos: mouseX, yPos: mouseY }]);
    } else {
      mouseX = e.clientX - divRect.left;
      mouseY = e.clientY - divRect.top;
      setXYSignature({ xPos: mouseX, yPos: mouseY });
    }
  };

  //function for capture position of x and y on hover signature button last position
  const handleMouseLeave = () => {
    setSignBtnPosition([xySignature]);
  };
  const handleSaveWidgetsOptions = (
    dropdownName,
    dropdownOptions,
    minCount,
    maxCount,
    isReadOnly,
    addOption,
    deleteOption,
    status,
    defaultValue,
    isHideLabel
  ) => {
    const filterSignerPos = signerPos.filter((data) => data.Id === uniqueId);
    if (filterSignerPos.length > 0) {
      const getPlaceHolder = filterSignerPos[0].placeHolder;
      const getPageNumer = getPlaceHolder.filter(
        (data) => data.pageNumber === pageNumber
      );
      if (getPageNumer.length > 0) {
        const getXYdata = getPageNumer[0].pos;
        const getPosData = getXYdata;
        const addSignPos = getPosData.map((position) => {
          if (position.key === signKey) {
            if (widgetType === radioButtonWidget) {
              if (addOption) {
                return {
                  ...position,
                  Height: position.Height
                    ? position.Height + 15
                    : defaultWidthHeight(widgetType).height + 15
                };
              } else if (deleteOption) {
                return {
                  ...position,
                  Height: position.Height
                    ? position.Height - 15
                    : defaultWidthHeight(widgetType).height - 15
                };
              } else {
                return {
                  ...position,
                  options: {
                    ...position.options,
                    name: dropdownName,
                    values: dropdownOptions,
                    isReadOnly: isReadOnly || false,
                    isHideLabel: isHideLabel || false,
                    defaultValue: defaultValue,
                    fontSize:
                      fontSize || currWidgetsDetails?.options?.fontSize || 12,
                    fontColor:
                      fontColor ||
                      currWidgetsDetails?.options?.fontColor ||
                      "black"
                  }
                };
              }
            } else if (widgetType === "checkbox") {
              if (addOption) {
                return {
                  ...position,
                  Height: position.Height
                    ? position.Height + 15
                    : defaultWidthHeight(widgetType).height + 15
                };
              } else if (deleteOption) {
                return {
                  ...position,
                  Height: position.Height
                    ? position.Height - 15
                    : defaultWidthHeight(widgetType).height - 15
                };
              } else {
                return {
                  ...position,
                  options: {
                    ...position.options,
                    name: dropdownName,
                    values: dropdownOptions,
                    validation: {
                      minRequiredCount: minCount,
                      maxRequiredCount: maxCount
                    },
                    defaultValue: defaultValue,
                    isReadOnly: isReadOnly || false,
                    isHideLabel: isHideLabel || false,
                    fontSize:
                      fontSize || currWidgetsDetails?.options?.fontSize || 12,
                    fontColor:
                      fontColor ||
                      currWidgetsDetails?.options?.fontColor ||
                      "black"
                  }
                };
              }
            } else {
              return {
                ...position,
                options: {
                  ...position.options,
                  name: dropdownName,
                  status: status,
                  values: dropdownOptions,
                  defaultValue: defaultValue,
                  fontSize:
                    fontSize || currWidgetsDetails?.options?.fontSize || 12,
                  fontColor:
                    fontColor ||
                    currWidgetsDetails?.options?.fontColor ||
                    "black"
                }
              };
            }
          }
          return position;
        });

        const newUpdateSignPos = getPlaceHolder.map((obj) => {
          if (obj.pageNumber === pageNumber) {
            return { ...obj, pos: addSignPos };
          }
          return obj;
        });
        const newUpdateSigner = signerPos.map((obj) => {
          if (obj.Id === uniqueId) {
            return { ...obj, placeHolder: newUpdateSignPos };
          }
          return obj;
        });

        setSignerPos(newUpdateSigner);
        if (!addOption && !deleteOption) {
          handleNameModal();
        }
      }
    }
    setFontSize();
    setFontColor();
  };
  const handleWidgetdefaultdata = (defaultdata, isSignWidget) => {
    if (isSignWidget) {
      const updatedPdfDetails = [...pdfDetails];
      const signtypes = defaultdata.signatureType || signatureType;
      updatedPdfDetails[0].SignatureType = signtypes;
      // Update the SignatureType with the modified array
      setPdfDetails(updatedPdfDetails);
      setSignatureType(signtypes);
    }
    const options = ["email", "number", "text"];
    let inputype;
    if (defaultdata.textvalidate) {
      inputype = options.includes(defaultdata.textvalidate)
        ? defaultdata.textvalidate
        : "regex";
    }
    const filterSignerPos = signerPos.filter((data) => data.Id === uniqueId);
    if (filterSignerPos.length > 0) {
      const getPlaceHolder = filterSignerPos[0].placeHolder;

      const getPageNumer = getPlaceHolder.filter(
        (data) => data.pageNumber === pageNumber
      );

      if (getPageNumer.length > 0) {
        const getXYdata = getPageNumer[0].pos;
        const getPosData = getXYdata;
        const addSignPos = getPosData.map((position) => {
          if (position.key === signKey) {
            if (position.type === textInputWidget) {
              return {
                ...position,
                options: {
                  ...position.options,
                  name: defaultdata?.name || "text",
                  status: defaultdata?.status || "required",
                  hint: defaultdata?.hint || "",
                  defaultValue: defaultdata?.defaultValue || "",
                  validation:
                    isSubscribed && inputype
                      ? {
                          type: inputype,
                          pattern:
                            inputype === "regex" ? defaultdata.textvalidate : ""
                        }
                      : {},
                  fontSize:
                    fontSize || currWidgetsDetails?.options?.fontSize || 12,
                  fontColor:
                    fontColor ||
                    currWidgetsDetails?.options?.fontColor ||
                    "black"
                }
              };
            } else {
              return {
                ...position,
                options: {
                  ...position.options,
                  name: defaultdata.name,
                  status: defaultdata.status,
                  defaultValue: defaultdata.defaultValue,
                  fontSize:
                    fontSize || currWidgetsDetails?.options?.fontSize || 12,
                  fontColor:
                    fontColor ||
                    currWidgetsDetails?.options?.fontColor ||
                    "black"
                }
              };
            }
          }
          return position;
        });

        const newUpdateSignPos = getPlaceHolder.map((obj) => {
          if (obj.pageNumber === pageNumber) {
            return { ...obj, pos: addSignPos };
          }
          return obj;
        });
        const newUpdateSigner = signerPos.map((obj) => {
          if (obj.Id === uniqueId) {
            return { ...obj, placeHolder: newUpdateSignPos };
          }
          return obj;
        });
        setSignerPos(newUpdateSigner);
      }
    }
    setCurrWidgetsDetails({});
    setFontSize();
    setFontColor();
    handleNameModal();
  };

  const handleNameModal = () => {
    setIsNameModal(false);
    setCurrWidgetsDetails({});
    setIsCheckbox(false);
    setIsPageCopy(false);
  };
  const clickOnZoomIn = () => {
    onClickZoomIn(scale, zoomPercent, setScale, setZoomPercent);
  };
  const clickOnZoomOut = () => {
    onClickZoomOut(zoomPercent, scale, setZoomPercent, setScale);
  };
  //`handleRotationFun` function is used to roatate pdf particular page
  const handleRotationFun = async (rotateDegree) => {
    const rotatePlaceholderExist = handleRotateWarning(signerPos, pageNumber);
    //show rotation alert if widgets already exist
    if (rotatePlaceholderExist) {
      setShowRotateAlert({ status: true, degree: rotateDegree });
    } else {
      const urlDetails = await rotatePdfPage(
        rotateDegree,
        pageNumber - 1,
        pdfArrayBuffer
      );
      setPdfArrayBuffer(urlDetails.arrayBuffer);
      setPdfBase64Url(urlDetails.base64);
    }
  };
  const handleRemovePlaceholder = async () => {
    handleRemoveWidgets(
      setSignerPos,
      signerPos,
      pageNumber,
      setShowRotateAlert
    );
    const urlDetails = await rotatePdfPage(
      showRotateAlert.degree,
      pageNumber - 1,
      pdfArrayBuffer
    );
    setPdfArrayBuffer(urlDetails.arrayBuffer);
    setPdfBase64Url(urlDetails.base64);
  };
  async function checkIsSubscribed(extUserId) {
    const isGuestSign = true;
    const isPublic = false;
    const res = await fetchSubscription(
      extUserId,
      contactBookId,
      isGuestSign,
      isPublic
    );
    const plan = res.plan;
    const billingDate = res?.billingDate;
    const status = res?.status;

    if (plan === "freeplan") {
      return true;
    } else if (billingDate) {
      if (new Date(billingDate) > new Date()) {
        setIsSubscribed(true);
        return true;
      } else {
        if (isGuestSign) {
          setIsSubscriptionExpired(true);
        } else {
          handleNavigation(plan);
        }
      }
    } else if (isGuestSign) {
      if (status) {
        setIsSubscribed(true);
        return true;
      } else {
        setIsSubscriptionExpired(true);
      }
    } else {
      if (isGuestSign) {
        setIsSubscriptionExpired(true);
      } else {
        handleNavigation(res.plan);
      }
    }
  }
  //function for embed signature or image url in pdf
  async function embedWidgetsData() {
    try {
      const xyPosition = signerPos.filter(
        (data) => data.signerObjId === signerObjectId
      );
      if (xyPosition && xyPosition.length > 0) {
        let showAlert = false,
          isSignatureExist,
          widgetKey,
          TourPageNumber; // `pageNumber` is used to check on which page user did not fill widget's data then change current pageNumber and show tour message on that page
        for (let i = 0; i < xyPosition[0]?.placeHolder?.length; i++) {
          const updatePage = xyPosition[0].placeHolder[i]?.pageNumber;
          const requiredWidgets = xyPosition[0].placeHolder[i].pos.filter(
            (position) => position.type !== "checkbox"
          );
          if (requiredWidgets && requiredWidgets?.length > 0) {
            let checkSigned;
            for (let i = 0; i < requiredWidgets?.length; i++) {
              checkSigned = requiredWidgets[i]?.options?.response;

              if (!checkSigned) {
                const checkSignUrl = requiredWidgets[i]?.pos?.SignUrl;
                let checkDefaultSigned =
                  requiredWidgets[i]?.options?.defaultValue;
                if (!checkSignUrl && !checkDefaultSigned && !showAlert) {
                  showAlert = true;
                  TourPageNumber = updatePage;
                  widgetKey = requiredWidgets[i].key;
                }
              }
            }
          }
          //condition to check exist signature widget or not
          if (!isSignatureExist) {
            isSignatureExist = xyPosition[0].placeHolder[i].pos.some(
              (data) => data?.type === "signature"
            );
          }
        }

        if (xyPosition.length === 0 || !isSignatureExist) {
          setIsAlert({
            header: t("fields-required"),
            isShow: true,
            alertMessage: t("signature-widget-alert-1")
          });
          return;
        } else if (showAlert) {
          setPageNumber(TourPageNumber);
          setWidgetsTour(true);
          setUnSignedWidgetId(widgetKey);
        } else {
          setIsUiLoading(true);
          // `widgets` is Used to return widgets details with page number of current user
          const widgets = xyPosition?.[0]?.placeHolder;
          // Load a PDFDocument from the existing PDF base64
          const existingPdfBytes =
            "data:application/pdf;base64," + pdfBase64Url;
          try {
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const isSignYourSelfFlow = false;
            const extUserPtr = pdfDetails[0].ExtUserPtr;
            const HeaderDocId = extUserPtr?.HeaderDocId;
            //embed document's object id to all pages in pdf document
            if (!HeaderDocId) {
              await embedDocId(pdfDoc, documentId, allPages);
            }
            //embed multi signature in pdf
            const pdfBytes = await multiSignEmbed(
              widgets,
              pdfDoc,
              isSignYourSelfFlow,
              scale
            );
            // get ExistUserPtr object id of user class to get tenantDetails
            if (!pdfBytes?.error) {
              const objectId = pdfDetails?.[0]?.ExtUserPtr?.UserId?.objectId;
              let activeMailAdapter =
                pdfDetails?.[0]?.ExtUserPtr?.active_mail_adapter;
              //function for call to embed signature in pdf and get digital signature pdf
              const resSign = await signPdfFun(
                pdfBytes,
                documentId,
                signerObjectId,
                objectId,
                isSubscribed,
                activeMailAdapter,
                widgets,
                "self-sign"
              );
              if (resSign && resSign.status === "success") {
                setSignedSigners(pdfDetails[0]?.Signers);
                setIsSigned(true);
                const updateDoc = await getDocumentDetails();
                const url = updateDoc?.[0]?.SignedUrl || updateDoc?.[0]?.URL;
                const fileAdapter = updateDoc?.[0]?.FileAdapterId
                  ? `&adapter=${encodeURIComponent(updateDoc?.[0]?.FileAdapterId)}`
                  : "";
                const isCompleted = updateDoc?.[0]?.IsCompleted
                  ? `&completed=true`
                  : "";
                const params = `docid=${updateDoc[0].objectId}&docurl=${encodeURIComponent(url)}${isCompleted}${fileAdapter}`;
                window.location.href = `/success?${params}`;
              } else {
                setIsUiLoading(false);
                setIsAlert({
                  title: "Error",
                  isShow: true,
                  alertMessage: resSign.message
                });
              }
            } else {
              setIsUiLoading(false);
              setIsAlert({
                title: "Error",
                isShow: true,
                alertMessage: t("pdf-uncompatible")
              });
            }
          } catch (err) {
            setIsUiLoading(false);
            if (err && err.message.includes("is encrypted.")) {
              setIsAlert({
                isShow: true,
                alertMessage: t("encrypted-pdf-not-support")
              });
            } else {
              console.log("err in request signing", err);
              setIsAlert({
                isShow: true,
                alertMessage: t("something-went-wrong-mssg")
              });
            }
          }
        }
      } else {
        setIsAlert({
          isShow: true,
          alertMessage: t("something-went-wrong-mssg")
        });
      }
    } catch (err) {
      console.log("err in embedsign", err);
      setIsUiLoading(false);
      setIsAlert({
        isShow: true,
        alertMessage: t("something-went-wrong-mssg")
      });
    }
  }
  //function for save button to save signature or image url
  const saveSign = (type, isDefaultSign, width, height, typedSignature) => {
    const widgetsType = currWidgetsDetails?.type;
    const isTypeText = width && height ? true : false;
    const signatureImg = signature;
    let imgWH = { width: width ? width : "", height: height ? height : "" };
    setIsSignPad(false);
    setIsImageSelect(false);
    setImage();

    //get current signers placeholder position data
    const currentSigner = signerPos.filter(
      (data) => data.signerObjId === signerObjectId
    );
    //get current pagenumber placeholder index
    const getIndex = currentSigner[0]?.placeHolder.findIndex((object) => {
      return object.pageNumber === pageNumber;
    });
    const isAutoSign = false;
    //`isApplyAll` is used when user edit signature/initial then updated signature apply all existing drawn signatures
    const isApplyAll = true;
    //get current signer placeholder position data
    const placeholderPosition = currentSigner[0].placeHolder;
    //function of save signature image and get updated position with signature image url
    const getUpdatePosition = onSaveSign(
      type,
      placeholderPosition,
      getIndex,
      signKey,
      signatureImg,
      imgWH,
      isDefaultSign,
      isTypeText,
      typedSignature,
      isAutoSign,
      widgetsType,
      isApplyAll
    );

    const updateSignerData = currentSigner.map((obj) => {
      if (obj.signerObjId === signerObjectId) {
        return { ...obj, placeHolder: getUpdatePosition };
      }
      return obj;
    });

    const index = signerPos.findIndex(
      (data) => data.signerObjId === signerObjectId
    );
    setSignerPos((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1, ...updateSignerData);
      return newState;
    });
  };
  //function for image upload or update
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      compressedFileSize(file, setImgWH, setImage);
    }
  };
  //function for upload stamp image
  const saveImage = () => {
    const widgetsType = currWidgetsDetails?.type;
    //get current signers placeholder position data
    const currentSigner = signerPos.filter(
      (data) => data.signerObjId === signerObjectId
    );
    //get current pagenumber placeholder index
    const getIndex = currentSigner[0].placeHolder.findIndex((object) => {
      return object.pageNumber === pageNumber;
    });
    //`isApplyAll` is used when user edit stamp then updated signature apply all existing drawn signatures
    const isApplyAll = true;
    const isAutoSign = false;
    //get current signer placeholder position data
    const placeholderPosition = currentSigner[0].placeHolder;
    //function of save image and get updated position with image url
    const getUpdatePosition = onSaveImage(
      placeholderPosition,
      getIndex,
      signKey,
      imgWH,
      image,
      isAutoSign,
      widgetsType,
      isApplyAll
    );

    //replace updated placeholder position with old data
    placeholderPosition.splice(
      0,
      placeholderPosition.length,
      ...getUpdatePosition
    );
    //get current signers placeholder position data index number in array
    const indexofSigner = signerPos.findIndex((object) => {
      return object.signerObjId === signerObjectId;
    });
    //update current signers data with new placeholder position array data
    setSignerPos((prevState) => {
      const newState = [...prevState]; // Create a copy of the state
      newState.splice(indexofSigner, 1, ...currentSigner); // Modify the copy
      return newState; // Update the state with the modified copy
    });
  };

  const handleTextSettingModal = (value) => {
    setIsTextSetting(value);
  };
  const handleSaveFontSize = () => {
    const filterSignerPos = signerPos.filter((data) => data.Id === uniqueId);
    if (filterSignerPos.length > 0) {
      const getPlaceHolder = filterSignerPos[0].placeHolder;

      const getPageNumer = getPlaceHolder.filter(
        (data) => data.pageNumber === pageNumber
      );

      if (getPageNumer.length > 0) {
        const getXYdata = getPageNumer[0].pos;
        const getPosData = getXYdata;
        const addSignPos = getPosData.map((position) => {
          if (position.key === signKey) {
            return {
              ...position,
              options: {
                ...position.options,
                fontSize:
                  fontSize || currWidgetsDetails?.options?.fontSize || 12,
                fontColor:
                  fontColor || currWidgetsDetails?.options?.fontColor || "black"
              }
            };
          }
          return position;
        });

        const newUpdateSignPos = getPlaceHolder.map((obj) => {
          if (obj.pageNumber === pageNumber) {
            return { ...obj, pos: addSignPos };
          }
          return obj;
        });
        const newUpdateSigner = signerPos.map((obj) => {
          if (obj.Id === uniqueId) {
            return { ...obj, placeHolder: newUpdateSignPos };
          }
          return obj;
        });
        setSignerPos(newUpdateSigner);
      }
    }
    setCurrWidgetsDetails({});
    setFontSize();
    setFontColor();
    handleNameModal();
    handleTextSettingModal(false);
  };
  const handleDownloadBtn = async () => {
    const url = pdfDetails?.[0]?.SignedUrl || pdfDetails?.[0]?.URL;
    const name =
      pdfDetails?.[0]?.Name?.length > 100
        ? pdfDetails?.[0]?.Name?.slice(0, 100)
        : pdfDetails?.[0]?.Name || "Document";
    await fetchUrl(url, name);
  };
  const handleExpiry = async (expiryDate) => {
    setIsUiLoading(true);
    const doc = pdfDetails?.[0];
    const oldExpiryDate = new Date(doc?.ExpiryDate?.iso);
    const newExpiryDate = new Date(expiryDate);
    if (newExpiryDate > oldExpiryDate) {
      const updateExpiryDate = new Date(expiryDate).toISOString();
      const expiryIsoFormat = { iso: updateExpiryDate, __type: "Date" };
      try {
        const serverUrl = serverUrl_fn();
        const url = serverUrl + `/classes/contracts_Document/`;
        const body = { ExpiryDate: expiryIsoFormat };
        const res = await axios.put(url + doc.objectId, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
            "X-Parse-Session-Token": localStorage.getItem("accesstoken")
          }
        });
        if (res.data && res.data.updatedAt) {
          setIsExpired(false);
          let doc = pdfDetails?.[0];
          doc.ExpiryDate = expiryIsoFormat;
          setPdfDetails([doc]);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsUiLoading(false);
      }
    } else {
      setIsUiLoading(false);
      alert(t("expiry-date-error"));
    }
  };
  //function for update TourStatus
  const closeEmptyWidgetTourStep = async () => {
    setWidgetsTour(false);
  };

  const emptyWidgetTourStep = [
    {
      selector: '[data-tut="IsSigned"]',
      content: t("signature-validate-alert-2"),
      position: "top",
      style: { fontSize: "13px" }
    }
  ];

  // `handleRedirectCancel` is used to cancel redirecting to redirectUrl
  const handleRedirectCancel = () => {
    setIsredirectCanceled(true);
  };
  return (
    <>
      <Title title={state?.title ? state.title : "New Document"} />
      <DndProvider backend={HTML5Backend}>
        {isLoading.isLoad ? (
          <LoaderWithMsg isLoading={isLoading} />
        ) : handleError ? (
          <HandleError handleError={handleError} />
        ) : isSubscriptionExpired ? (
          <ModalUi
            title={t("subscription-expired")}
            isOpen={isSubscriptionExpired}
            showClose={false}
          >
            <div className="flex flex-col justify-center items-center py-4 md:py-5 gap-5">
              <p className="text-sm md:text-lg font-normal">
                {t("owner-subscription-expired")}
              </p>
            </div>
          </ModalUi>
        ) : (
          <div>
            {isUiLoading && (
              <div className="absolute h-[100vh] w-full flex flex-col justify-center items-center z-[999] bg-[#e6f2f2] bg-opacity-80">
                <Loader />
                <span className="text-[13px] text-base-content">
                  {t("loading-mssg")}
                </span>
              </div>
            )}

            {isCelebration && (
              <div className="relative z-[1000]">
                <Confetti
                  width={window.innerWidth}
                  height={window.innerHeight}
                  recycle={false} // Prevents confetti from repeating
                  gravity={0.1} // Adjust the gravity to control the speed
                />
              </div>
            )}
            <div className="relative op-card overflow-hidden flex flex-col md:flex-row justify-between bg-base-300">
              {/* this component used for UI interaction and show their functionality */}
              <Tour
                showNumber={false}
                showNavigation={false}
                showNavigationNumber={false}
                onRequestClose={closeEmptyWidgetTourStep}
                steps={emptyWidgetTourStep}
                isOpen={widgetsTour}
                rounded={5}
                closeWithMask={false}
              />

              <PdfDeclineModal
                show={isExpired}
                doc={pdfDetails?.[0]}
                headMsg={t("expired-doc-title")}
                bodyMssg={t("expired-on-mssg", { expiredDate })}
                isDownloadBtn={true}
                handleDownloadBtn={handleDownloadBtn}
                handleExpiry={handleExpiry}
              />
              {/* this component used to render all pdf pages in left side */}
              <RenderAllPdfPage
                signerPos={signerPos}
                id={uniqueId}
                allPages={allPages}
                setAllPages={setAllPages}
                setPageNumber={setPageNumber}
                setSignBtnPosition={setSignBtnPosition}
                pageNumber={pageNumber}
                pdfBase64Url={pdfBase64Url}
                signedUrl={pdfDetails?.[0]?.SignedUrl || ""}
                setPdfArrayBuffer={setPdfArrayBuffer}
                setPdfBase64Url={setPdfBase64Url}
                pdfArrayBuffer={pdfArrayBuffer}
                isMergePdfBtn={true}
              />
              {/* pdf render view */}
              <div className=" w-full md:w-[57%] flex mr-4">
                <PdfZoom
                  clickOnZoomIn={clickOnZoomIn}
                  clickOnZoomOut={clickOnZoomOut}
                  handleRotationFun={handleRotationFun}
                  pdfArrayBuffer={pdfArrayBuffer}
                  pageNumber={pageNumber}
                  setPdfBase64Url={setPdfBase64Url}
                  setPdfArrayBuffer={setPdfArrayBuffer}
                  setSignerPos={setSignerPos}
                  signerPos={signerPos}
                  userId={uniqueId}
                  allPages={allPages}
                  setAllPages={setAllPages}
                  setPageNumber={setPageNumber}
                  isDisableEditTools={isCompleted.isCertificate}
                />
                <div className=" w-full md:w-[95%] ">
                  <ModalUi
                    isOpen={isAlert.isShow}
                    title={isAlert?.header || t("alert")}
                    handleClose={() =>
                      setIsAlert({ isShow: false, alertMessage: "" })
                    }
                  >
                    <div className="p-[20px] h-full">
                      <p>{isAlert.alertMessage}</p>
                    </div>
                  </ModalUi>
                  {/* this modal is used show this document is already sign */}
                  <ModalUi
                    isOpen={isCompleted.isModal}
                    title={t("document-signed")}
                    handleClose={() =>
                      setIsCompleted((prev) => ({ ...prev, isModal: false }))
                    }
                    reduceWidth={
                      !isCompleted?.message &&
                      "md:min-w-[440px] md:max-w-[400px]"
                    }
                  >
                    <div className="h-full p-[20px] text-base-content">
                      {isCompleted?.message ? (
                        <>
                          <p>{isCompleted?.message}</p>
                          {!isredirectCanceled && redirectUrl && (
                            <div className="flex flex-row gap-1 items-center justify-center mb-3 mt-2">
                              <p>
                                Redirecting you in {redirectTimeLeft} sec...
                              </p>
                              <button
                                onClick={handleRedirectCancel}
                                className="underline cursor-pointer op-text-primary focus:outline-none ml-2"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="px-[15px]">
                          <span>{t("document-signed-alert-4")}</span>
                        </div>
                      )}
                      {!isCompleted?.message && (
                        <div className="flex flex-col mt-3 gap-1 px-[10px] justify-center items-center">
                          {!isredirectCanceled && redirectUrl && (
                            <div className="flex flex-row gap-1 items-center justify-center mb-3">
                              <p>
                                Redirecting you in {redirectTimeLeft} sec...
                              </p>
                              <button
                                onClick={handleRedirectCancel}
                                className="underline cursor-pointer op-text-primary focus:outline-none ml-2"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                          <div className={`${!redirectUrl ? "m-2" : ""}`}>
                            <button
                              onClick={(e) =>
                                handleToPrint(e, setIsDownloading, pdfDetails)
                              }
                              type="button"
                              className="font-[500] text-[13px] mr-[5px] op-btn op-btn-neutral"
                            >
                              <i
                                className="fa-light fa-print"
                                aria-hidden="true"
                              ></i>
                              <span className="hidden lg:block">
                                {t("print")}
                              </span>
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleDownloadCertificate(
                                  pdfDetails,
                                  setIsDownloading
                                )
                              }
                              className="font-[500] text-[13px] mr-[5px] op-btn op-btn-secondary"
                            >
                              <i
                                className="fa-light fa-award mx-[3px] lg:mx-0"
                                aria-hidden="true"
                              ></i>
                              <span className="hidden lg:block">
                                {t("certificate")}
                              </span>
                            </button>
                            <button
                              type="button"
                              className="font-[500] text-[13px] mr-[5px] op-btn op-btn-primary"
                              onClick={() => {
                                setIsCompleted((prev) => ({
                                  ...prev,
                                  isModal: false
                                }));
                                setIsDownloadModal(true);
                              }}
                            >
                              <i
                                className="fa-light fa-download"
                                aria-hidden="true"
                              ></i>
                              <span className="hidden lg:block">
                                {t("download")}
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </ModalUi>
                  <PlaceholderCopy
                    isPageCopy={isPageCopy}
                    setIsPageCopy={setIsPageCopy}
                    xyPosition={signerPos}
                    setXyPosition={setSignerPos}
                    allPages={allPages}
                    pageNumber={pageNumber}
                    signKey={signKey}
                    Id={uniqueId}
                    widgetType={widgetType}
                    setUniqueId={setUniqueId}
                  />

                  <DropdownWidgetOption
                    type="checkbox"
                    title={t("checkbox")}
                    showDropdown={isCheckbox}
                    setShowDropdown={setIsCheckbox}
                    handleSaveWidgetsOptions={handleSaveWidgetsOptions}
                    currWidgetsDetails={currWidgetsDetails}
                    setCurrWidgetsDetails={setCurrWidgetsDetails}
                    handleClose={handleNameModal}
                    fontSize={fontSize}
                    setFontSize={setFontSize}
                    fontColor={fontColor}
                    setFontColor={setFontColor}
                    isShowAdvanceFeature={false}
                  />
                  {isDownloading === "pdf" && (
                    <div className="fixed z-[1000] inset-0 flex justify-center items-center bg-black bg-opacity-30">
                      <Loader />
                    </div>
                  )}
                  <ModalUi
                    isOpen={
                      isDownloading === "certificate" ||
                      isDownloading === "certificate_err"
                    }
                    title={
                      isDownloading === "certificate" ||
                      isDownloading === "certificate_err"
                        ? t("generating-certificate")
                        : t("pdf-download")
                    }
                    handleClose={() => setIsDownloading("")}
                  >
                    <div className="p-3 md:p-5 text-[13px] md:text-base text-center text-base-content">
                      {isDownloading === "certificate" ? (
                        <p>{t("generate-certificate-alert")}</p>
                      ) : (
                        <p>{t("generate-certificate-err")}</p>
                      )}
                    </div>
                  </ModalUi>
                  {/* this component is used for signature pad modal */}
                  {documentId && isSignPad && (
                    <SignPad
                      isInitial={isInitial}
                      setIsInitial={setIsInitial}
                      signatureTypes={signatureType}
                      isSignPad={isSignPad}
                      isStamp={isStamp}
                      setIsImageSelect={setIsImageSelect}
                      setIsSignPad={setIsSignPad}
                      setImage={setImage}
                      isImageSelect={isImageSelect}
                      imageRef={imageRef}
                      onImageChange={onImageChange}
                      setSignature={setSignature}
                      image={image}
                      onSaveImage={saveImage}
                      onSaveSign={saveSign}
                      setIsStamp={setIsStamp}
                      currWidgetsDetails={currWidgetsDetails}
                      setCurrWidgetsDetails={setCurrWidgetsDetails}
                    />
                  )}
                  {/* pdf header which contain funish back button */}
                  <Header
                    isSigned={isSigned}
                    isSelfSign={true}
                    pageNumber={pageNumber}
                    allPages={allPages}
                    changePage={changePage}
                    pdfDetails={pdfDetails}
                    signerPos={signerPos}
                    signersdata={signersdata}
                    isShowHeader={true}
                    currentSigner={true}
                    handleRotationFun={handleRotationFun}
                    clickOnZoomIn={clickOnZoomIn}
                    clickOnZoomOut={clickOnZoomOut}
                    pdfArrayBuffer={pdfArrayBuffer}
                    setPdfArrayBuffer={setPdfArrayBuffer}
                    setPdfBase64Url={setPdfBase64Url}
                    setSignerPos={setSignerPos}
                    userId={uniqueId}
                    embedWidgetsData={embedWidgetsData}
                    pdfBase64={pdfBase64Url}
                    setIsDownloadModal={setIsDownloadModal}
                    isCompleted={isCompleted.isCertificate}
                  />

                  <div
                    ref={divRef}
                    data-tut="pdfArea"
                    className="h-full md:h-[95%]"
                  >
                    {containerWH && (
                      <RenderPdf
                        pageNumber={pageNumber}
                        pdfNewWidth={pdfNewWidth}
                        pdfDetails={pdfDetails}
                        signerPos={signerPos}
                        successEmail={false}
                        numPages={numPages}
                        pageDetails={pageDetails}
                        setIsInitial={setIsInitial}
                        drop={drop}
                        handleDeleteSign={handleDeleteSign}
                        handleTabDrag={handleTabDrag}
                        handleStop={handleStop}
                        setPdfLoad={setPdfLoad}
                        pdfLoad={pdfLoad}
                        setSignerPos={setSignerPos}
                        containerWH={containerWH}
                        setIsResize={setIsResize}
                        isResize={isResize}
                        setZIndex={setZIndex}
                        setIsPageCopy={setIsPageCopy}
                        signersdata={signersdata}
                        setSignKey={setSignKey}
                        setWidgetType={setWidgetType}
                        setUniqueId={setUniqueId}
                        isDragging={isDragging}
                        isSelfSign={true}
                        handleTextSettingModal={handleTextSettingModal}
                        setIsCheckbox={setIsCheckbox}
                        setCurrWidgetsDetails={setCurrWidgetsDetails}
                        setSelectWidgetId={setSelectWidgetId}
                        selectWidgetId={selectWidgetId}
                        handleNameModal={setIsNameModal}
                        uniqueId={uniqueId}
                        pdfOriginalWH={pdfOriginalWH}
                        setScale={setScale}
                        scale={scale}
                        setIsSelectId={setIsSelectId}
                        pdfBase64Url={pdfBase64Url}
                        fontSize={fontSize}
                        setFontSize={setFontSize}
                        fontColor={fontColor}
                        setFontColor={setFontColor}
                        unSignedWidgetId={unSignedWidgetId}
                        setIsSignPad={setIsSignPad}
                        setIsStamp={setIsStamp}
                        signerObjectId={signerObjectId}
                        setValidateAlert={setValidateAlert}
                        isCompleted={isCompleted?.isCertificate}
                        signedSigners={signedSigners}
                        divRef={divRef}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* signature button */}
              <div className="w-full md:w-[23%] bg-base-100 overflow-y-auto hide-scrollbar">
                <div className={`max-h-screen`}>
                  {isMobile && !isCompleted.isCertificate ? (
                    <div>
                      <WidgetComponent
                        dragSignature={dragSignature}
                        handleDivClick={handleDivClick}
                        handleMouseLeave={handleMouseLeave}
                        isDragSign={isDragSign}
                        dragStamp={dragStamp}
                        dragRef={dragRef}
                        isDragStamp={isDragStamp}
                        isSignYourself={true}
                        addPositionOfSignature={addPositionOfSignature}
                        signerPos={signerPos}
                        signersdata={signersdata}
                        isSelectListId={isSelectListId}
                        setIsSelectId={setIsSelectId}
                        isSigners={false}
                        setUniqueId={setUniqueId}
                        initial={true}
                        setSignersData={setSignersData}
                        blockColor={blockColor}
                        setBlockColor={setBlockColor}
                        uniqueId={uniqueId}
                        setSelectWidgetId={setSelectWidgetId}
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        className="hidden md:block w-full h-full bg-base-100"
                        aria-disabled
                      >
                        <Signedby
                          pdfDetails={pdfDetails[0]}
                          isSelfSign={true}
                        />
                        {!isCompleted.isCertificate && (
                          <div data-tut="addWidgets">
                            <WidgetComponent
                              dragSignature={dragSignature}
                              handleDivClick={handleDivClick}
                              handleMouseLeave={handleMouseLeave}
                              isDragSign={isDragSign}
                              dragStamp={dragStamp}
                              dragRef={dragRef}
                              isDragStamp={isDragStamp}
                              isSignYourself={true}
                              addPositionOfSignature={addPositionOfSignature}
                              initial={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalUi
          isOpen={validateAlert}
          title={t("validation-alert")}
          handleClose={() => setValidateAlert(false)}
        >
          <div className="h-[100%] p-[20px]">
            <p>{t("validation-alert-1")}</p>
            <div className="h-[1px] bg-[#9f9f9f] w-full my-[15px]"></div>
            <button
              onClick={() => setValidateAlert(false)}
              type="button"
              className="op-btn op-btn-ghost"
            >
              {t("close")}
            </button>
          </div>
        </ModalUi>

        <WidgetNameModal
          signatureType={signatureType}
          widgetName={widgetName}
          defaultdata={currWidgetsDetails}
          isOpen={isNameModal}
          handleClose={handleNameModal}
          handleData={handleWidgetdefaultdata}
          isTextSetting={isTextSetting}
          setIsTextSetting={setIsTextSetting}
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontColor={fontColor}
          setFontColor={setFontColor}
        />
        <RotateAlert
          showRotateAlert={showRotateAlert.status}
          setShowRotateAlert={setShowRotateAlert}
          handleRemoveWidgets={handleRemovePlaceholder}
        />
        <DownloadPdfZip
          setIsDownloadModal={setIsDownloadModal}
          isDownloadModal={isDownloadModal}
          pdfDetails={pdfDetails}
          isDocId={true}
          pdfBase64={pdfBase64Url}
        />
        <TextFontSetting
          isTextSetting={isTextSetting}
          setIsTextSetting={setIsTextSetting}
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontColor={fontColor}
          setFontColor={setFontColor}
          handleSaveFontSize={handleSaveFontSize}
          currWidgetsDetails={currWidgetsDetails}
        />
      </DndProvider>
    </>
  );
}

export default SelfSign;
